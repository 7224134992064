.circle-graph-container {
  width: 80%;
  display: flex;
  justify-content: center;
}

.star {
  color: #f8ce46;
}

.percent-text {
  width: 80%;
  text-align: center;
  line-height: 2em;
}

.percent-text * {
  display: inline-block;
}

.chart-text {
  text-align: center;
  color: red;
}

.data-text {
  line-height: 1em;
}

.chart-text h4 {
  font-size: 55px;
  font-weight: 600;
}

.black-check-txt {
  width: 80%;
}

.chart-section {
  width: 75%;
}

@media screen and (max-width: 1400px) {
  .black-check-txt {
    width: 95vw;
  }
}

@media screen and (max-width: 796px) {
  .black-check-txt {
    width: 100%;
  }

  .chart-section {
    width: 100%;
  }
}
