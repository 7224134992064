.calendly-inline-widget {
  min-width: 320px;
  height: 1200px;
}
.demo-title {
  margin-bottom: 0px;
}

@media only screen and (min-width: 992px) {
  .demo-title {
    margin-bottom: 0px;
  }
}