* {
  font-family: "Source Sans Pro", sans-serif;
}

.company-container {
  max-width: 1920px;
}

.black-line-break {
  width: 100%;
  height: 0.1em;
  background-color: #333333;
}

.btn-sigma {
  background-color: #34d1b6;
  border: 0;
  height: 3em;
  min-width: 10em;
  box-shadow: 0 3px 3px -1px #333333;
}

.btn-sigma:hover {
  background-color: #907bf0;
  border: 0;
}

.btn-purple {
  background-color: #907bf0;
  height: 4em;
  border: 0;
  box-shadow: 0 3px 10px -3px #333333;
}

.btn-purple:hover {
  background-color: #907bf0;
  box-shadow: 0 3px 15px #333333;
}

.green-check {
  color: #34d1b6;
  min-width: 4em;
}

.contact-form {
  width: 55%;
  border-radius: 0.6em;
  box-shadow: 3px 3px 5px;
}

.contact-form input {
  height: 3em;
  box-shadow: inset 0px 0px 3px #333333;
  border: 0 !important;
}

.contact-form input:focus {
  border: 1px solid #907bf0 !important;
  box-shadow: inset 0px 0px 6px #333333;
}

.contact-form label {
  font-size: 24px;
  width: 50%;
}

.form-contact-text {
  line-height: 1em;
  font-size: 14px !important;
}
.sigma-image-container {
  width: 90%;
}

.sigma-image {
  width: 100%;
}

.response-item {
  background-color: #f6f6f6;
  border-radius: 1em;
  width: 77%;
}

.no-response-item {
  background-color: #f6f6f6;
  border-radius: 1em;
  width: 75%;
}

.from-txt div {
  text-overflow: ellipsis;
  overflow: hidden;
}

.join-text {
  line-height: 1em;
}

.company-error {
  background-color: #907bf0;
  border-radius: 1em;
  box-shadow: 0 0 4px;
}

@media screen and (max-width: 1024px) {
  .contact-form {
    width: 95%;
    padding: 1em;
  }
}

@media screen and (max-width: 796px) {
  .sigma-image-container {
    width: 100%;
  }
  .response-item {
    width: 100%;
  }
  .no-response-item {
    width: 100%;
  }
}
