.affix {
  position: sticky;
  top: 20px;
  margin-top: 100px;
}

.middle-gray-line {
  border-left: 1px solid lightGray;
  left: 5%;
}


@media only screen and (min-width: 992px) {
  .affix {
    margin-top: 0px;
  }
}

@media only screen and (min-width: 992px) {
  .middle-gray-line {
    left: 10%;
  }
}

@media only screen and (min-width: 1200px) {
  .middle-gray-line {
    left: 5%;
  }
}