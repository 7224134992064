.gif-section {
  background-color: #333333;
  width: 98%;
  border-bottom-left-radius: 20%;
  border-bottom-right-radius: 20%;
}

.gif-row {
  margin-top: max(8%, 6em);
}

.img-circle {
  width: 5em;
  height: 5em;
  border: 3px solid #34d1b6;
  border-radius: 50%;
}

.gauge-bar {
  display: flex;
  justify-content: center;
  margin-bottom: 1em;
  margin-top: 2em;
}

.laptop-gif-container {
  position: relative;
  width: 600px;
  height: 325px;
}

.laptop-img {
  width: 600px;
  height: 325px;
  position: relative;
  top: 0;
  left: 0;
}

.gif-container {
  position: absolute;
  top: 20px;
  left: 64px;
  width: 468px;
  height: 270px;
}

.gif {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
}

.requested-text {
  line-height: 1.2em;
}

.large-report-text {
  line-height: 1em;
}

@media screen and (max-width: 769px) {
  .requested-text {
    text-align: center;
  }

  .large-report-text {
    text-align: center;
    padding: 0 0.8em 0 0.8em;
  }
}

@media screen and (max-width: 1201px) {
  .laptop-gif-container {
    width: 400px;
    height: 225px;
  }

  .laptop-img {
    width: 400px;
    height: 225px;
    top: 0;
    left: 0;
  }

  .gif-container {
    top: 20px;
    left: 56px;
    width: 294px;
    height: 174px;
  }
}
