.content-section {
  display: flex;
  justify-content: center;
  margin-top: 4em;
  width: 80%;
}

.section-head {
  background-color: #333333;
  border-radius: 1em;
  width: 75%;
}

.line {
  width: 85%;
  height: 0.6em;
  background-color: #34d1b6;
}

.response-icon-green {
  color: #34d1b6;
}
.response-icon-red {
  color: red;
}

.content-demo-container {
  width: 25%;
}

.content-gauge-container {
}

.content-gauge-container {
  max-width: 20em;
}

@media screen and (max-width: 796px) {
  .content-demo-container {
    width: 100%;
  }
  .content-section {
    width: 90%;
  }
  .section-head {
    width: 100%;
  }
}
