@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  src: local("GlacialIndifference"),
    url(/fonts/Roboto-Regular.otf) format("opentype");
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: bold;
  src: local("GlacialIndifference"),
    url(/fonts/Roboto-Bold.otf) format("opentype");
}

@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: normal;
  src: local("GlacialIndifference"),
    url(/fonts/Roboto-Italic.otf) format("opentype");
}

body {
  font-family: "GlacialIndifference", sans-serif;
}

p {
  font-size: 1.2rem;
}

.bg-green {
  background-color: #34d1b6;
}

.bg-blue {
  background-color: #165bdf;
}

.bg-purple {
  background-color: #907bf0;
}

.text-alpha-1 {
  color: rgba(0, 0, 0, 0.2);
}

.text-alpha-2 {
  color: rgba(0, 0, 0, 0.6);
}

.green-background {
  background: #34d1b6;
  position: absolute;
  height: 400px;
  width: 100%;
  top: 56px;
  left: 0px;
}

.rounded-white-box {
  background-color: white;
}

.bg-recommendations {
  background-color: rgba(52, 209, 182, 0.1);
}

.bg-above-average {
  background-color: rgba(52, 209, 182, 0.1);
}

.bg-average {
  background-color: rgba(255, 179, 64, 0.1);
}

.bg-below-average {
  background-color: rgba(235, 87, 87, 0.1);
}

hr {
  margin-top: 1.4px;
  margin-bottom: 1.4px;
}

.small-hr {
  margin-top: 1.4px;
  margin-bottom: 1.4px;
}

.text-12 {
  font-size: 12px;
}

.text-14 {
  font-size: 14px;
}

.text-18 {
  font-size: 18px;
}

.line-height-1 {
  line-height: 1;
}

.highcharts-container {
  margin: 0 auto;
}

.text-image-overlay {
  position: absolute;
  top: 0px;
  left: 0px;
}
