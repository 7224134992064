.typography {
  color: #333333;
  white-space: pre-line;
}

.txt-white {
  color: white;
}

.txt-green {
  color: #34d1b6;
}

.txt-red {
  color: red;
}

.txt-small {
  font-size: 20px;
}

.txt-medium {
  font-size: 22px;
}

.txt-large {
  font-size: 28px;
}

.txt-xlarge {
  font-size: 42px;
}

.txt-xxlarge {
  font-size: 48px;
}

.txt-italics {
  font-style: italic;
}

.txt-bold {
  font-weight: 600;
}

.txt-center {
  text-align: center;
}

@media screen and (max-width: 1600px) {
  .txt-xxlarge {
    font-size: 40px;
  }
  .txt-medium {
    font-size: 18px;
  }

  .txt-large {
    font-size: 28px;
  }
}

@media screen and (max-width: 1200px) {
  .txt-xxlarge {
    font-size: 40px;
  }
  .txt-medium {
    font-size: 18px;
  }
}

@media screen and (max-width: 796px) {
  .mobile-center-text {
    text-align: center;
  }

  .txt-large {
    font-size: 28px;
  }
  .txt-xlarge {
    font-size: 36px;
  }
  .txt-xxlarge {
    font-size: 36px;
  }
}
