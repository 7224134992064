.header {
  z-index: 2;
  position: fixed;
  top: 0;
  width: 100%;
  box-shadow: 0 10px 10px -10px rgb(0, 0, 0, 0.25);
  height: 7%;
  min-height: 5em;
  max-height: 8em;
  background-color: white;
  display: flex;
  justify-content: center;
}

.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1920px;
  height: 100%;
  width: 100%;
}

.img-logo {
  height: 100%;
}

.logo-container {
  margin-left: 4em;
}

.header-btn-container {
  margin-right: 4em;
  max-width: 13em;
}

@media screen and (max-width: 796px) {
  .header {
    max-height: 5em;
  }

  .logo-container {
    margin-left: 3em;
  }
  .header-btn-container {
    margin-right: 3em;
  }

  .header-btn-container button {
    height: 3em;
  }
}
